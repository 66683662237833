import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-75850a3f"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dialog-footer marginTop30px text-right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createBlock(_component_el_dialog, {
    title: "学生信息编辑",
    class: "info-div600",
    style: {
      "padding": "24px",
      "text-align": "left"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: "用户名",
      "label-width": "80px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.Info.username,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.Info.username = $event),
        autocomplete: "off",
        disabled: $data.Info.id ? true : false,
        placeholder: "请输入"
      }, null, 8, ["modelValue", "disabled"])]),
      _: 1
    }), !$data.Info.id ? (_openBlock(), _createBlock(_component_el_form_item, {
      key: 0,
      label: "密码",
      "label-width": "80px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.Info.password,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.Info.password = $event),
        autocomplete: "off",
        type: "password",
        placeholder: "请输入"
      }, null, 8, ["modelValue"])]),
      _: 1
    })) : _createCommentVNode("", true), _createVNode(_component_el_form_item, {
      label: "学号",
      "label-width": "80px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.Info.code,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.Info.code = $event),
        autocomplete: "off",
        disabled: $data.Info.id ? true : false,
        placeholder: "唯一标识",
        maxlength: "20"
      }, null, 8, ["modelValue", "disabled"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "姓名",
      "label-width": "80px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.Info.name,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.Info.name = $event),
        autocomplete: "off",
        placeholder: "请输入"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "院校",
      "label-width": "80px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.Info.school,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.Info.school = $event),
        autocomplete: "off",
        placeholder: "请输入"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "专业",
      "label-width": "80px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.Info.major,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $data.Info.major = $event),
        autocomplete: "off",
        placeholder: "请输入"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "班级",
      "label-width": "80px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.Info.clasz,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => $data.Info.clasz = $event),
        autocomplete: "off",
        placeholder: "请输入"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "手机号",
      "label-width": "80px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.Info.phone,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => $data.Info.phone = $event),
        autocomplete: "off",
        placeholder: "请输入"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "邮箱",
      "label-width": "80px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.Info.email,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => $data.Info.email = $event),
        autocomplete: "off",
        placeholder: "填写邮箱"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createElementVNode("div", _hoisted_1, [_createElementVNode("button", {
      class: "subBtn",
      onClick: _cache[9] || (_cache[9] = (...args) => $options.subData && $options.subData(...args))
    }, "提交"), _createElementVNode("button", {
      class: "qxBtn",
      onClick: _cache[10] || (_cache[10] = (...args) => $options.closeDiv && $options.closeDiv(...args))
    }, "取消")])]),
    _: 1
  });
}